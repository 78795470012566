import { isNullOrUndefined } from 'util';
import { NoteTypeInput } from 'src/app/model/admin/note-type-input';
import { NoteType } from 'src/app/model/crm/note-type';

export function isValidNoteTypeInput(input: NoteTypeInput, types: NoteType[]): any {
    if (!isNullOrUndefined(types) && !isNullOrUndefined(input)) {
        const isDuplicate = types.find((c) => c.name === input.name && c.id !== input.id && c.category === input.category);
        if (!isNullOrUndefined(isDuplicate)) {
            return 'CRM.MESSAGES.ERROR.DUPLICATE_TYPE';
        }
    }

    return null;
}
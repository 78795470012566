<div id="job-roles-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  <div
    class="ui-g ui-fluid"
    *ngIf="ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])"
  >
    <div style="text-align: right">
      <div style="text-align: right">
        <app-progress-spinner [enabled]="saving"></app-progress-spinner>
      </div>
    </div>
  </div>
  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>

  <div class="content">
    <div class="button-wrapper group content-header">
      <button
        pButton
        class="width-eighty"
        label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
        *ngIf="canCreate"
        icon="fa fa-fw fa-plus-circle"
        (click)="newJobRole()"
      ></button>
      <button
        pButton
        class="refresh-button width-twenty"
        icon="fa fa-fw fa-refresh"
        (click)="refresh()"
      ></button>
    </div>

    <div *ngIf="ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])">
      <app-general-grid
        [values]="elements"
        [columns]="columns"
        [loading]="loading"
        [deviceSize]="getDeviceSize()"
        [totalRecords]="totalRecords"
        [filterable]="true"
        [screenName]="screenName"
        [rows]="rows"
        [lazy]="true"
        (lazyLoad)="lazyLoad($event)"
        [includeExportCSV]="false"
        (changeViewEvent)="changeViewEvent($event)"
        scrollHeight="calc(100vh - 325px)"
        [usePaginator]="true"
        [alwaysShowPaginator]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        #grid
      >
      </app-general-grid>
    </div>

    <p-dialog
      [header]="dialogHeader"
      [(visible)]="displayDialog"
      showEffect="fade"
      [modal]="true"
      (onHide)="dialogClosed()"
      [transitionOptions]="'0ms'"
      [style]="{ width: '17vw' }"
    >
      <form [formGroup]="jobRoleForm">
        <div class="ui-g ui-fluid" id="job-role-form">
          <div class="no-padding modal-input">
            <div class="label">{{ "ADMIN.LABEL.NAME" | translate }}:</div>
            <div>
              <input
                class="input"
                type="text"
                pInputText
                formControlName="name"
                (change)="nameChanged()"
              />
            </div>
          </div>
          <div class="no-padding modal-input">
            <div class="label">{{ "CRM.LABEL.SHIFT_GROUP" | translate }}:</div>
            <div class="input">
              <p-multiSelect
                formControlName="shiftGroup"
                [options]="shiftGroups"
                optionLabel="label"
                appendTo="body"
                [filter]="true"
                [virtualScroll]="true"
                [virtualScrollItemSize]="30"
                [panelStyle]="{ width: '10.25vw' }"
                (onChange)="shiftGroupChanged()"
              ></p-multiSelect>
            </div>
          </div>
          <div class="no-padding modal-input">
            <div class="label">{{ "COMMON.LABEL.ENABLE" | translate }}:</div>
            <div class="label">
              <p-checkbox
                formControlName="enabled"
                [binary]="true"
                (onChange)="toggleRecord($event)"
              ></p-checkbox>
            </div>
          </div>
          <div class="no-padding" *ngIf="showAudit">
            <app-audit-dialog-container
              #audit
              [showAsDialog]="displayAuditDialog"
              [auditHistory]="history"
              [loading]="auditLoading"
            ></app-audit-dialog-container>
          </div>
          <div style="margin-top: 8px">
            <div class="button-wrapper" *ngIf="canCreate || canEdit">
              <button
                pButton
                label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                icon="fa fa-fw fa-save"
                (click)="saveJobRole()"
                [disabled]="!this.jobRoleForm.valid"
              ></button>
            </div>
            <div class="button-wrapper" *ngIf="canDelete === true">
              <button
                pButton
                label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
                icon="fa fa-fw fa-trash"
                (click)="deleteJobRole()"
              ></button>
            </div>
          </div>
        </div>
      </form>
    </p-dialog>
  </div>

  <div *ngIf="ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])">
    <p-message
      severity="info"
      text="{{ 'COMMON.LABEL.RESOLUTION_NOT_SUPPORTED' | translate }}"
    ></p-message>
  </div>
</div>

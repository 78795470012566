import { Column } from '../../model/common/column';

export const EMAIL_GROUP_TABLE_DEFINITION: Column[] = [
  {
    field: 'name',
    header: 'ADMIN.LABEL.EMAIL_GROUP',
    mobileHeader: 'ADMIN.LABEL.EMAIL_GROUP',
    formatPattern: 'text',
    type: 'input',
    width: '30%',
    sortable: true,
    filterable: true,
    matchMode: 'contains'
  },
  {
    field: "businessUnitString",
    header: "COMMON.LABEL.BUSINESS_UNITS",
    mobileHeader: "COMMON.LABEL.BUSINESS_UNITS",
    formatPattern: "text",
    type: "input",
    width: "45%",
    sortable: true,
    filterable: true,
    matchMode: "contains",
  },
  {
    field: 'enabled',
    header: 'COMMON.LABEL.ENABLED',
    mobileHeader: 'COMMON.LABEL.ENABLED',
    formatPattern: 'text',
    width: '15%',
    sortable: true,
    filterable: true,
    matchMode: 'startsWith'
  },
];

import { Column } from '../../model/common/column';
import { SelectItem } from 'primeng/api';

const trueFalse: SelectItem[] = [
    {
        label: 'Choose',
        value: null
    },
    {
        label: 'true',
        value: true
    },
    {
        label: 'false',
        value: false
    }
];

export function getJobRoleColumns(shiftGroups: {label: string, value: string}[] ): Column[] {
  return [
        {
          field: "name",
          header: "CONTACT.LABEL.JOB_ROLE",
          mobileHeader: "CONTACT.LABEL.JOB_ROLE",
          formatPattern: "text",
          type: "input",
          width: "40%",
          sortable: true,
          filterable: true,
          matchMode: "contains",
        },
        {
          field: "shiftGroupsStr",
          header: "CRM.LABEL.SHIFT_GROUP",
          mobileHeader: "CRM.LABEL.SHIFT_GROUP",
          formatPattern: "text",
          type: "dropdown",
          width: "35%",
          sortable: true,
          filterable: true,
          options: [{label: 'Choose', value: null}, ...shiftGroups], 
          matchMode: "startsWith"
        },
        {
          field: "enabled",
          header: "COMMON.LABEL.ENABLED",
          mobileHeader: "COMMON.LABEL.ENABLED",
          formatPattern: "text",
          width: "15%",
          sortable: true,
          filterable: true,
          type: "dropdown",
          options: trueFalse,
          matchMode: "equals",
        },
      ];
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { EmailGroupContainerComponent } from './containers/email-group-container.component';
import { MonLogTypesContainerComponent } from './containers/mon-log-types-container.component';
import { RoleBusinessUnitAssignContainerComponent } from './containers/roles/role-business-units-container/role-business-unit-assign-container.component';
import { RoleClaimAssignContainerComponent } from './containers/roles/role-claim-container/role-claim-assign-container.component';
import { ClaimRoleAssignContainerComponent } from './containers/roles/claim-role-container/claim-role-assign-container.component';

const routes: Routes = [
  { path: 'emailGroups', component: EmailGroupContainerComponent, canActivate: [AuthGuard] },
  { path: 'claimAssignment', component: RoleClaimAssignContainerComponent, canActivate: [AuthGuard] },
  { path: 'roleAssignment', component: ClaimRoleAssignContainerComponent, canActivate: [AuthGuard] },
  { path: 'businessUnitAssignment', component: RoleBusinessUnitAssignContainerComponent, canActivate: [AuthGuard] },
  { path: 'monLogTypes', component: MonLogTypesContainerComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }

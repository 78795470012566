import { Injectable } from '@angular/core';
import { EmailGroup } from '../model/admin/email-group';
import { EmailGroupMember } from '../model/admin/email-group-member';
import { LazyLoadEvent } from 'primeng/api';
import { BaseTableService } from './base-table.service';

@Injectable({
  providedIn: 'root'
})
export class AdminTableService extends BaseTableService<EmailGroup> {
  private members: EmailGroupMember[] = [];
  private adminLazyLoadEvents: Map<string, LazyLoadEvent> = new Map<string, LazyLoadEvent>();

  constructor() {
    super();
  }

  getEmailGroupMembers(): EmailGroupMember[] {
    return this.members;
  }

  setEmailGroupMembers(members: EmailGroupMember[]): void {
    this.members = members;
  }

  getLastAdminLazyLoad(key: string) {
    return this.adminLazyLoadEvents.get(key);
  }

  setLastAdminLazyLoad(key: string, lastLazy: LazyLoadEvent) {
    this.adminLazyLoadEvents.set(key, Object.assign({}, lastLazy));
  }
}

import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuditDialogContainerComponent } from 'src/app/core/containers/audit-dialog-container.component';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { buildAuditHistory } from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { MitigationDataTableInput } from 'src/app/model/admin/mitigation-data-table-input';
import { Audit } from 'src/app/model/common/audit';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { ShiftGroup } from 'src/app/model/shift/shift-group';
import { ShiftMitigation } from 'src/app/model/shift/shift-mitigation';
import { AdminBuilderService } from 'src/app/services/admin-builder.service';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { ShiftTurnoverApiService } from 'src/app/services/shift-turnover-api.service';
import { isNullOrUndefined } from 'util';

import { SHIFT_MITIGATION_TABLE_DEFINITION } from '../definitions/data-table-definitions';

@Component({
  selector: 'app-shift-mitigation-container',
  templateUrl: './shift-mitigation-container.component.html',
  styleUrls: ['./shift-mitigation-container.component.scss']
})
export class ShiftMitigationContainerComponent extends SearchContainerComponent<ShiftMitigation> implements OnInit, OnDestroy {

  @ViewChild('audit', { static: false }) audit: AuditDialogContainerComponent;

  canCreate = false;
  canEdit = false;
  canDelete = false;
  saving = false;
  form: UntypedFormGroup;
  displayDialog = false;
  dialogHeader: string;
  groups: SelectItem[];

  mitigations: ShiftMitigation[] = [];
  selectedMitigation: ShiftMitigation;
  showSuccessMessage = true;
  sub: Subscription;

  auditLoading = false;
  showAudit = true;
  displayAuditDialog = false;
  history: Audit[];

  activeTabChangedSub: Subscription;

  constructor(protected _deviceService: DeviceService,
    protected _auth: AuthService,
    protected _shiftApi: ShiftTurnoverApiService,
    protected _adminBuilder: AdminBuilderService,
    protected _translateService: TranslateService,
    protected _logAndMessage: LogAndMessageService,
    protected _tabService: TabService,
    protected _adminTableService: AdminTableService,
    private _fb: UntypedFormBuilder,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    private _confirmationService: ConfirmationService,
    private _authApi: AuthApiService) {
    super(_deviceService, _loader, _auth, _cdRef);

  }

  ngOnInit() {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.setupActiveTabChangedSubscription();
    this.form = this._fb.group({
      id: [null],
      shiftGroup: [{value: null, disabled: true}, Validators.required],
      name: [{value: null, disabled: false}, Validators.required],
      sortOrder: [{value: null, disabled: false}, Validators.required],
      enabled: [{value: false, disabled: false}],
      version: [null]
    });

    this.columns = SHIFT_MITIGATION_TABLE_DEFINITION;

    const screenName$ = this._translateService.get('CRM.SCREEN.FATIGUE_MITIGATIONS');
    const dialogHeader$ = this._translateService.get('CRM.LABEL.FATIGUE_MITIGATIONS_DETAILS');
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList(['']);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList(['']);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList(['']);

    if (this.canCreate || this.canEdit) {
      this.form.enable();
    }

    this._shiftApi.loadAvailableGroups().pipe(take(1)).subscribe(({data}) => {
      this.groups = [{label: 'Choose', value: null}, ...data.getShiftGroups.map((sg) => ({label: sg.name, value: sg.id}))];
      this.applyPreviousData();
      this.applyPreviousFilters();
    });
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  handleLazyLoad(req, $event, filters) {
    this._adminTableService.setLastAdminLazyLoad(this.screenName, $event.lazy);
    const query = {
      name: null,
      ShiftGroup: null,
      sortOrder: null,
      enabled: null
    };

    // TODO: update to include sortOrder and groupName
    if (filters.name) { query.name = filters.name.value; }
    if (filters.ShiftGroup) { query.ShiftGroup = filters.ShiftGroup.value; }
    if (filters.sortOrder) { query.sortOrder = filters.sortOrder.value; }
    if (filters.enabled && !isNullOrUndefined(filters.enabled.value)) {
      if (filters.enabled.value) {
        query.enabled = 'true';
      } else {
        query.enabled = 'false';
      }
    }

    this.queryNetwork(req, $event, query);
  }

  queryNetwork(req, $event, query): void {
    req.page += 1;
    const sort = this.buildNetworkSortQuery($event, 'name');
    this._shiftApi.queryForShiftMitigations(req.pageSize, req.page, query, sort).pipe(take(1)).subscribe(({ data }) => {
      const clone = Object.assign({}, data);
      this.mitigations  = clone.queryForShiftMitigations.items;
      this.totalRecords = clone.queryForShiftMitigations.totalRecords;
      this.elements = [...this.mitigations];
    }, (error) => {
      this.loading = false;
      this._logAndMessage.errorLogOnly(error);
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'CRM.MESSAGES.ERROR.QUERY_SHIFT_MITIGATION',
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }, () => {
      this.loading = false;
      if (this.showSuccessMessage) {
        this.showSuccessMessage = false;
        this._logAndMessage.translateToSuccessMessage({
          bodyKey: 'CRM.MESSAGES.SUCCESS.QUERY_SHIFT_MITIGATION',
          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
        });
      }
    });
  }

  changeViewEvent($event): void {
    this.selectedMitigation = $event.data;
    this.form.patchValue({
      id: $event.data.id,
      name: $event.data.name,
      shiftGroup: ($event.data.ShiftGroup ? $event.data.ShiftGroup.id : null),
      sortOrder: $event.data.sortOrder,
      enabled: $event.data.enabled,
      version: $event.data.version,
    });
    this.form.controls['shiftGroup'].disable();
    this.showAudit = true;
    this.loadAuditHistory();
    this.displayDialog = true;
  }

  clearForm(): void {
    this.form.reset();
  }

  dialogClosed(): void {
    this.clearForm();
    this.selectedMitigation = undefined;
    this.history = [];
    if (!isNullOrUndefined(this.audit)) {
      this.audit.closeTogglePanel();
    }
  }

  newShiftMitigation(): void {
    this.form.reset();
    this.history = [];
    this.showAudit = false;
    this.form.controls['enabled'].patchValue(false, { emitEvent: true });
    this.form.controls['shiftGroup'].enable();
    this.displayDialog = true;
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  nameChanged(): void {
    if (!isNullOrUndefined(this.selectedMitigation)) {
      this.selectedMitigation.name = this.form.controls['name'].value;
    }
  }

  orderChanged(): void {
    if (!isNullOrUndefined(this.selectedMitigation)) {
      this.selectedMitigation.sortOrder = this.form.controls['sortOrder'].value;
    }
  }

  groupChanged($event): void {
    if (!isNullOrUndefined($event) && !isNullOrUndefined($event.value) && !isNullOrUndefined(this.groups)) {
      const shiftGroup = this.groups.find((sg) => sg.value === $event.value);
      if (!isNullOrUndefined(this.selectedMitigation) && !isNullOrUndefined(shiftGroup)) {
        this.selectedMitigation.ShiftGroup = { id: shiftGroup.value, name: shiftGroup.label };
      }
    }
  }

  toggleRecord($event): void {
    if (!isNullOrUndefined($event) && !isNullOrUndefined(this.selectedMitigation)) {
      this.selectedMitigation.enabled = $event;
    }
  }

  saveShiftMitigation(): void {
    if (!this.canCreate && !this.canEdit) {
      return;
    }

    if (!isNullOrUndefined(this.form) && this.form.valid) {
      let mitigation = null;
      if (!isNullOrUndefined(this.selectedMitigation)) {
        mitigation = this.selectedMitigation;
      } else {
        mitigation = Object.assign({}, this.form.getRawValue());
      }
      if (!mitigation.id) {
        this.createShiftMitigation(mitigation);
      } else {
        this.updateShiftMitigation(mitigation, true);
      }
    }
  }

  createShiftMitigation(mitigation: ShiftMitigation) {
    if (!this.canCreate) {
      return;
    }

    mitigation.dirtyStatus = DirtyStatus.NEW;
    const mitigationInput: MitigationDataTableInput = this._adminBuilder.buildMitigationDataTableInput(mitigation, true);

    this.saving = true;
    this._shiftApi.createShiftMitigation(mitigationInput).pipe(take(1)).subscribe((result) => {
      this.clearForm();
      this.displayDialog = false;
    }, (error) => {
      this.saving = false;
      this._logAndMessage.errorLogOnly(error);
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'CRM.MESSAGES.ERROR.CREATE_SHIFT_MITIGATION',
        bodyParams: { name: mitigation.name },
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }, () => {
      this.saving = false;
      this._logAndMessage.translateToSuccessMessage({
        bodyKey: 'CRM.MESSAGES.SUCCESS.CREATE_SHIFT_MITIGATION',
        bodyParams: { name: mitigation.name },
        headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
      });
      this.refresh();
    });
  }

  updateShiftMitigation(changes: ShiftMitigation, closeDialog: boolean) {
    if (!this.canEdit) {
      return;
    }

    changes.dirtyStatus = DirtyStatus.UPDATED;
    const mitigationChanges: MitigationDataTableInput = this._adminBuilder.buildMitigationDataTableInput(changes, false);

    this.saving = true;
    this._shiftApi.updateShiftMitigation(changes.id, mitigationChanges).pipe(take(1)).subscribe((result) => {
      if (!closeDialog && !isNullOrUndefined(result)) {
        this.changeViewEvent({ data: result.data.updateShiftMitigation });
      } else {
        this.clearForm();
        this.displayDialog = false;
        this.refresh();
      }
    }, (error) => {
      this.saving = false;
      this._logAndMessage.errorLogOnly(error);
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'CRM.MESSAGES.ERROR.UPDATE_SHIFT_MITIGATION',
        bodyParams: {name: changes.name},
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }, () => {
      this.saving = false;
      this._logAndMessage.translateToSuccessMessage({
        bodyKey: 'CRM.MESSAGES.SUCCESS.UPDATE_SHIFT_MITIGATION',
        bodyParams: {name: changes.name},
        headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
      });
    });
  }

  deleteShiftMitigation(): void {
    if (!this.canDelete) {
      return;
    }

    if (!isNullOrUndefined(this.form) && this.form.valid) {
      const mitigation = Object.assign({}, this.form.getRawValue());
      let confHeader = null, ok = null, cancel = null, message = null;
      const confHeader$ = this._translateService.get('CRM.MESSAGES.HEADERS.DELETE_MITIGATION');
      const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
      const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
      const message$ = this._translateService.get('CRM.MESSAGES.CONFIRMATION.DELETE_MITIGATION', {name: mitigation.name});

      forkJoin([confHeader$, ok$, cancel$, message$]).subscribe((messages) => {
        confHeader = messages[0];
        ok = messages[1];
        cancel = messages[2];
        message = messages[3];
      });

      this._confirmationService.confirm({
        header: confHeader,
        icon: 'fa fa-question-circle',
        acceptVisible: true,
        rejectVisible: true,
        acceptLabel: ok,
        rejectLabel: cancel,
        message: message,
        accept: () => {
          this.saving = true;
          if (!isNullOrUndefined(mitigation.id)) { // Existing ShiftMitigation so delete it
            this._shiftApi.allowShiftMitigationDelete(mitigation.id).pipe(take(1)).subscribe((canDelete) => {
              if (!isNullOrUndefined(canDelete) && !isNullOrUndefined(canDelete.data) &&
                !isNullOrUndefined(canDelete.data.allowShiftMitigationDelete) && !canDelete.data.allowShiftMitigationDelete.isValid) {
                this.saving = false;
                this._logAndMessage.translateToErrorMessage({
                  bodyKey: canDelete.data.allowShiftMitigationDelete.message,
                  headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                });
              } else {
                this._shiftApi.deleteShiftMitigation(mitigation.id).pipe(take(1)).subscribe((result) => {
                  this.saving = false;
                  this.clearForm();
                  this.displayDialog = false;
                }, (error) => {
                  this._logAndMessage.errorLogOnly(error);
                  this._logAndMessage.translateToErrorMessage({
                    bodyKey: 'CRM.MESSAGES.ERROR.DELETE_SHIFT_MITIGATION',
                    bodyParams: { name: mitigation.name },
                    headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                  });
                }, () => {
                  this._logAndMessage.translateToSuccessMessage({
                    bodyKey: 'CRM.MESSAGES.SUCCESS.DELETE_SHIFT_MITIGATION',
                    bodyParams: { name: mitigation.name },
                    headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
                  });
                  this.refresh();
                });
              }
            });
          } else { // new ShiftMitigation so just close dialog
            this.displayDialog = false;
          }
        },
        reject: () => {

        }
      });
    }
  }

  loadAuditHistory(): void {
    this.auditLoading = true;
    this._shiftApi.getShiftMitigationHistory(this.selectedMitigation.id).pipe(take(1)).subscribe(({ data }) => {
      const clone = Object.assign({}, data);
      this.history = [...buildAuditHistory(clone.getShiftMitigationHistory)];
    }, (error) => {
      this._logAndMessage.errorLogOnly(error);
    }, () => {
      this.auditLoading = false;
    });
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub = TabService.getInstance().activeTabChanged.subscribe((tab) => {
      if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
        this._adminTableService.setLastLazyLoad(this._adminTableService.getLastAdminLazyLoad(this.screenName));
        this.refresh();
      }
    });
  }
}

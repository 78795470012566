<div id="app-note-types-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  <div
    class="ui-g ui-fluid"
    *ngIf="ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])"
  >
    <div class="button-header" style="text-align: right">
      <div style="text-align: right">
        <app-progress-spinner [enabled]="saving"></app-progress-spinner>
      </div>
      <div id="business-units-wrapper">
        <app-business-unit-filter
          (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)"
          #buFilter
        ></app-business-unit-filter>
      </div>
      <div class="button-wrapper group">
        <button
          pButton
          class="width-eighty"
          label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
          icon="fa fa-fw fa-plus-circle"
          (click)="newNoteType()"
          *ngIf="canCreate === true"
        ></button>
        <button
          pButton
          class="refresh-button width-twenty"
          icon="fa fa-fw fa-refresh"
          (click)="refresh()"
        ></button>
      </div>
    </div>
  </div>
  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
  <div
    [style.display]="!elements ? 'none' : ''"
    *ngIf="ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])"
  >
    <app-general-grid
      [values]="elements"
      [columns]="columns"
      [loading]="loading"
      [deviceSize]="getDeviceSize()"
      [totalRecords]="totalRecords"
      [filterable]="true"
      [screenName]="screenName"
      [rows]="rows"
      [lazy]="false"
      [includeExportCSV]="false"
      (changeViewEvent)="changeViewEvent($event)"
      scrollHeight="calc(100vh - 325px)"
      [usePaginator]="true"
      [rowsPerPageOptions]="[50, 100, 200]"
      #grid
    >
    </app-general-grid>
  </div>

  <div *ngIf="ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])">
    <p-message
      severity="info"
      text="{{ 'COMMON.LABEL.RESOLUTION_NOT_SUPPORTED' | translate }}"
    ></p-message>
  </div>

  <p-dialog
    [header]="dialogHeader"
    [(visible)]="displayDialog"
    showEffect="fade"
    [modal]="true"
    (onHide)="dialogClosed()"
    [transitionOptions]="'0ms'"
    [style]="{ width: '750px' }"
  >
    <form [formGroup]="form">
      <div class="ui-g ui-fluid" id="job-role-form">
        <div class="no-padding form">
          <div class="form-item">
            <div class="form-label">{{ "ADMIN.LABEL.NAME" | translate }}:</div>
            <div class="form-input">
              <input
                type="text"
                pInputText
                formControlName="name"
                (change)="nameChanged()"
              />
            </div>
          </div>

          <div class="form-item">
            <div class="form-label">
              {{ "COMMON.LABEL.ENABLE" | translate }}:
            </div>
            <div class="form-input">
              <p-checkbox
                formControlName="enabled"
                [binary]="true"
              ></p-checkbox>
            </div>
          </div>

          <div style="width: 100%">
            <p-pickList
              [source]="sourcePicklist"
              [target]="targetPicklist"
              targetHeader="Assigned Business Units"
              [dragdrop]="false"
              sourceHeader="Available Business Units"
              [responsive]="true"
              [showSourceControls]="false"
              [showTargetControls]="false"
              [sourceStyle]="{
                height: '250px',
                'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
              }"
              [targetStyle]="{
                height: '250px',
                'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
              }"
              [disabled]="form.disabled"
            >
              <ng-template let-businessUnit pTemplate="item">
                <div>
                  <div>
                    <h5>{{ businessUnit.name }}</h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>

          <div class="form-item">
            <div class="form-label">
              {{ "ADMIN.LABEL.CATEGORY" | translate }}:
            </div>
            <div>
              <p-dropdown
                [options]="categories"
                optionLabel="name"
                placeholder="Select Category"
                formControlName="category"
                appendTo="body"
              ></p-dropdown>
            </div>
          </div>
        </div>

        <div class="form-buttons">
          <div
            class="button-wrapper"
            *ngIf="canCreate === true || canEdit === true"
          >
            <button
              pButton
              label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
              icon="fa fa-fw fa-save"
              (click)="saveNoteType()"
              [disabled]="!this.form.valid"
            ></button>
          </div>
          <div class="button-wrapper" *ngIf="canDelete === true">
            <button
              pButton
              label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
              icon="fa fa-fw fa-trash"
              (click)="deleteNoteType()"
            ></button>
          </div>
        </div>
      </div>
    </form>
  </p-dialog>
</div>

import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuditDialogContainerComponent } from 'src/app/core/containers/audit-dialog-container.component';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { buildAuditHistory, isNullOrEmpty } from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { DataTableInput } from 'src/app/model/admin/data-table-input';
import { JobRole } from 'src/app/model/admin/job-role';
import { Audit } from 'src/app/model/common/audit';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { AdminBuilderService } from 'src/app/services/admin-builder.service';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactTableService } from 'src/app/services/contact-table.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { isNullOrUndefined } from 'util';
import { ShiftGroup } from 'src/app/model/shift/shift-group';
import { ShiftTurnoverApiService } from 'src/app/services/shift-turnover-api.service';
import { JobRoleDataTableInput } from 'src/app/model/admin/job-role-data-table-input';
import { getJobRoleColumns } from '../definitions/job-role-table-definition';
import { sortByProperty } from 'src/app/utils/utils';

@Component({
  selector: 'app-job-roles-container',
  templateUrl: './job-roles-container.component.html',
  styleUrls: ['./job-roles-container.component.scss']
})
export class JobRolesContainerComponent extends SearchContainerComponent<JobRole> implements OnInit, OnDestroy {

  @ViewChild('audit', { static: false }) audit: AuditDialogContainerComponent;

  canCreate = false;
  canEdit = false;
  canDelete = false;
  saving = false;
  jobRoleForm: UntypedFormGroup;
  displayDialog = false;
  dialogHeader: string;
  filteredContacts: any[];

  jobRoles: JobRole[] = [];
  selectedRole: JobRole;
  showSuccessMessage = true;
  shiftGroups: {label: string, value: string}[];
  selectedShiftGroups: string[] = [];

  auditLoading = false;
  showAudit = true;
  displayAuditDialog = false;
  history: Audit[];
  activeTabChangedSub: Subscription;

  constructor(protected _deviceService: DeviceService,
    protected _auth: AuthService,
    protected _contactApi: ContactApiService,
    protected _contactTable: ContactTableService,
    protected _adminBuilder: AdminBuilderService,
    protected _translateService: TranslateService,
    protected _logAndMessage: LogAndMessageService,
    protected _tabService: TabService,
    protected _adminTableService: AdminTableService,
    private _fb: UntypedFormBuilder,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    private _confirmationService: ConfirmationService,
    private _authApi: AuthApiService,
    protected _shiftTurnoverApi: ShiftTurnoverApiService) {
    super(_deviceService, _loader, _auth, _cdRef);

  }

  ngOnInit() {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.setupActiveTabChangedSubscription();
    this.loadShiftGroups();
    this.jobRoleForm = this._fb.group({
      id: [null],
      name: [{value: null, disabled: true}, Validators.required],
      shiftGroup: [null],
      enabled: [{value: false, disabled: true}],
      version: [null]
    });

    const screenName$ = this._translateService.get('CONTACT.SCREEN.JOB_ROLES');
    const dialogHeader$ = this._translateService.get('CONTACT.LABEL.JOB_ROLE_DETAILS');
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList(['CreateJobRoles']);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList(['EditJobRoles']);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList(['DeleteJobRoles']);

    this.jobRoleForm.disable();
    this.applyPreviousData(); // check into what these do
    this.applyPreviousFilters();
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  handleLazyLoad(req, $event, filters) {
    this._adminTableService.setLastAdminLazyLoad(this.screenName, $event.lazy);
    const query = {
      name: null,
      shiftGroups: null,
      enabled: null
    };

    if (filters.name) { query.name = filters.name.value; }
    if (filters.ShiftGroup) { query.shiftGroups = filters.ShiftGroup}
    if (filters.enabled && !isNullOrUndefined(filters.enabled.value)) {
      if (filters.enabled.value) {
        query.enabled = 'true';
      } else {
        query.enabled = 'false';
      }
    }

    this.queryNetwork(req, $event, query);
  }

  queryNetwork(req, $event, query): void {
    req.page += 1;
    const sort = this.buildNetworkSortQuery($event, 'name');
    this._contactApi.queryForJobRoles(req.pageSize, req.page, query, sort).pipe(take(1)).subscribe(({ data }) => {
      const clone = Object.assign({}, data);
      this.jobRoles  = clone.queryForJobRoles.items;
      this.jobRoles.forEach((jobRole) => {
        let shiftGroupNames = jobRole.shiftGroups.map((sg) => sg.name)
        jobRole.shiftGroupsStr = shiftGroupNames.join(", ")
      })
      this.totalRecords = clone.queryForJobRoles.totalRecords;
      this.elements = [...this.jobRoles];
    }, (error) => {
      this.loading = false;
      this._logAndMessage.errorLogOnly(error);
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'CONTACT.MESSAGES.ERROR.QUERY_JOB_ROLES',
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }, () => {
      this.loading = false;
      if (this.showSuccessMessage) {
        this.showSuccessMessage = false;
        this._logAndMessage.translateToSuccessMessage({
          bodyKey: 'CONTACT.MESSAGES.SUCCESS.QUERY_JOB_ROLES',
          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
        });
      }
    });
  }

  changeViewEvent($event): void {
    this.selectedRole = $event.data;
    this.jobRoleForm.patchValue({
      id: $event.data.id,
      name: $event.data.name,
      shiftGroup: $event.data.shiftGroups.map((sg) => {
        return {
          label: sg.name,
          value: sg.id
        }
      }),
      enabled: $event.data.enabled,
      version: $event.data.version,
    });
    this.showAudit = true;
    this.loadAuditHistory();
    this.selectedShiftGroups = $event.data.shiftGroups.map((sg) => sg.id);

    if (this.canEdit) {
      this.jobRoleForm.enable();
    }
    else {
      this.jobRoleForm.disable();
    }
    this.displayDialog = true;
  }

  clearForm(): void {
    this.jobRoleForm.reset();
  }

  dialogClosed(): void {
    this.clearForm();
    this.selectedRole = undefined;
    this.selectedShiftGroups = [];
    this.history = [];
    if (!isNullOrUndefined(this.audit)) {
      this.audit.closeTogglePanel();
    }
  }

  newJobRole(): void {
    this.jobRoleForm.reset();
    this.history = [];
    this.showAudit = false;
    this.jobRoleForm.controls['enabled'].patchValue(false, { emitEvent: true });
    if (this.canCreate) {
      this.jobRoleForm.enable();
    }
    else {
      this.jobRoleForm.disable();
    }
    this.displayDialog = true;
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  nameChanged(): void {
    if (!isNullOrUndefined(this.selectedRole)) {
      this.selectedRole.name = this.jobRoleForm.controls['name'].value;
    }
  }

  toggleRecord($event): void {
    if (!isNullOrUndefined($event) && !isNullOrUndefined(this.selectedRole)) {
      this.selectedRole.enabled = $event.checked;
    }
  }

  shiftGroupChanged() {
    this.selectedShiftGroups = [];
    if(!isNullOrUndefined(this.selectedRole)) {
      this.jobRoleForm.controls['shiftGroup'].value.forEach((sg) => {
        this.selectedShiftGroups.push(sg.value)
      });
    }
  }

  saveJobRole(): void {
    if (!this.canCreate && !this.canEdit) {
      return;
    }

    if (!isNullOrUndefined(this.jobRoleForm) && this.jobRoleForm.valid) {
      let jobRole = null;
      if (!isNullOrUndefined(this.selectedRole)) {
        jobRole = this.selectedRole;
      } else {
        jobRole = {
          name: this.jobRoleForm.controls['name'].value,
          enabled: this.jobRoleForm.controls['enabled'].value,
          version: this.jobRoleForm.controls['version'].value
        }
      }
      if (!jobRole.id) {
        this.createJobRole(jobRole);
      } else {
        this.updateJobRole(jobRole, true);
      }
    }
  }

  createJobRole(jobRole: JobRole) {
    if (!this.canCreate) {
      return;
    }

    this.jobRoleForm.controls['shiftGroup'].value.forEach((sg) => {
      this.selectedShiftGroups.push(sg.value)
    }),

    jobRole.dirtyStatus = DirtyStatus.NEW;
    const jobRoleInput: DataTableInput = this._adminBuilder.buildJobRoleDataTableInput(jobRole, true, this.selectedShiftGroups);

    this.saving = true;
    this._contactApi.createJobRole(jobRoleInput).pipe(take(1)).subscribe((result) => {
      this.clearForm();
      this.displayDialog = false;
    }, (error) => {
      this.saving = false;
      this._logAndMessage.errorLogOnly(error);
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'CONTACT.MESSAGES.ERROR.CREATE_JOB_ROLE',
        bodyParams: { name: jobRole.name },
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }, () => {
      this.saving = false;
      this._logAndMessage.translateToSuccessMessage({
        bodyKey: 'CONTACT.MESSAGES.SUCCESS.CREATE_JOB_ROLE',
        bodyParams: { name: jobRole.name },
        headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
      });
      this.refresh();
    });
  }

  updateJobRole(changes: JobRole, closeDialog: boolean) {
    if (!this.canEdit) {
      return;
    }

    changes.dirtyStatus = DirtyStatus.UPDATED;
    const jobRoleChanges: JobRoleDataTableInput = this._adminBuilder.buildJobRoleDataTableInput(changes, false, this.selectedShiftGroups);

    this.saving = true;
    this._contactApi.updateJobRole(changes.id, jobRoleChanges).pipe(take(1)).subscribe((result) => {
      if (!closeDialog && !isNullOrUndefined(result)) {
        this.changeViewEvent({ data: result.data.updateJobRole });
      } else {
        this.clearForm();
        this.displayDialog = false;
        this.refresh();
      }
    }, (error) => {
      this.saving = false;
      this._logAndMessage.errorLogOnly(error);
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'CONTACT.MESSAGES.ERROR.UPDATE_JOB_ROLE',
        bodyParams: {name: changes.name},
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }, () => {
      this.saving = false;
      this._logAndMessage.translateToSuccessMessage({
        bodyKey: 'CONTACT.MESSAGES.SUCCESS.UPDATE_JOB_ROLE',
        bodyParams: {name: changes.name},
        headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
      });
    });
  }

  deleteJobRole(): void {
    if (!this.canDelete) {
      return;
    }

    if (!isNullOrUndefined(this.jobRoleForm)) {
      const jobRole = Object.assign({}, this.jobRoleForm.getRawValue());
      let confHeader = null, ok = null, cancel = null, message = null;
      const confHeader$ = this._translateService.get('CONTACT.MESSAGES.HEADERS.DELETE_JOB_ROLE');
      const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
      const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
      const message$ = this._translateService.get('CONTACT.MESSAGES.CONFIRMATION.DELETE_JOB_ROLE', {name: jobRole.name});

      forkJoin([confHeader$, ok$, cancel$, message$]).subscribe((messages) => {
        confHeader = messages[0];
        ok = messages[1];
        cancel = messages[2];
        message = messages[3];
      });

      this._confirmationService.confirm({
        header: confHeader,
        icon: 'fa fa-question-circle',
        acceptVisible: true,
        rejectVisible: true,
        acceptLabel: ok,
        rejectLabel: cancel,
        message: message,
        accept: () => {
          this.saving = true;
          if (!isNullOrUndefined(jobRole.id)) { // Existing call type so delete it
            this._contactApi.allowJobRoleDelete(jobRole.id).pipe(take(1)).subscribe(({data}) => {
              if (!isNullOrUndefined(data.allowJobRoleDelete) && !data.allowJobRoleDelete.value) {
                this.saving = false;
                this._logAndMessage.translateToErrorMessage({
                  bodyKey: "Delete cannot be performed, the entry is already associated with records.",
                  headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                });
              } else {
                this._contactApi.deleteJobRole(jobRole.id).pipe(take(1)).subscribe((result) => {
                  this.saving = false;
                  this.clearForm();
                  this.displayDialog = false;
                }, (error) => {
                  this._logAndMessage.errorLogOnly(error);
                  this._logAndMessage.translateToErrorMessage({
                    bodyKey: 'CONTACT.MESSAGES.ERROR.DELETE_JOB_ROLE',
                    bodyParams: { name: jobRole.name },
                    headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                  });
                }, () => {
                  this._logAndMessage.translateToSuccessMessage({
                    bodyKey: 'CONTACT.MESSAGES.SUCCESS.DELETE_JOB_ROLE',
                    bodyParams: { name: jobRole.name },
                    headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
                  });
                  this.refresh();
                });
              }
            });
          } else { // new call type so just close dialog
            this.displayDialog = false;
          }
        },
        reject: () => {

        }
      });
    }
  }

  loadAuditHistory(): void {
    this.auditLoading = true;
    this._contactApi.getJobRoleHistory(this.selectedRole.id).pipe(take(1)).subscribe(({ data }) => {
      const clone = Object.assign({}, data);
      this.history = [...buildAuditHistory(clone.getJobRoleHistory)];
    }, (error) => {
      this._logAndMessage.errorLogOnly(error);
    }, () => {
      this.auditLoading = false;
    });
  }

  loadShiftGroups() {
    this._shiftTurnoverApi.loadAvailableGroups().pipe(take(1)).subscribe(({data}) => {
      this.shiftGroups = [...data.getShiftGroups.map((et) => ({ label: et.name, value: et.id}))].sort((a, b) => sortByProperty(a, b, 'label'));
      this.columns = getJobRoleColumns(this.shiftGroups)
    });
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub = TabService.getInstance().activeTabChanged.subscribe((tab) => {
      if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
        this._adminTableService.setLastLazyLoad(this._adminTableService.getLastAdminLazyLoad(this.screenName));
        this.refresh();
      }
    });
  }
}

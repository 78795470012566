import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Company } from 'src/app/model/contacts/company';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuditDialogContainerComponent } from 'src/app/core/containers/audit-dialog-container.component';
import { isNullOrUndefined } from 'util';
import { DeviceService } from 'src/app/services/device.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { AdminBuilderService } from 'src/app/services/admin-builder.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { TranslateService } from '@ngx-translate/core';
import { Audit } from 'src/app/model/common/audit';
import { TabService } from 'src/app/core/services/tab.service';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ConfirmationService } from 'primeng/api';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { COMPANY_TABLE_DEFINITION } from '../definitions/data-table-definitions';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompanyDataTableInput } from 'src/app/model/admin/company-data-table-input';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { buildAuditHistory } from 'src/app/core/functions/common-functions';
import { AuthApiService } from 'src/app/services/auth-api.service';

@Component({
  selector: 'app-company-container',
  templateUrl: './company-container.component.html',
  styleUrls: ['./company-container.component.scss']
})
export class CompanyContainerComponent extends SearchContainerComponent<Company> implements OnInit, OnDestroy {

  @ViewChild('audit', { static: false }) audit: AuditDialogContainerComponent;

  canEdit = false;
  canDelete = false;
  canCreate = false;
  saving = false;
  form: UntypedFormGroup;
  displayDialog = false;
  dialogHeader: string;
  filteredContacts: any[];

  companies: Company[] = [];
  selectedCompany: Company;
  showSuccessMessage = true;

  auditLoading = false;
  showAudit = true;
  displayAuditDialog = false;
  history: Audit[];
  activeTabChangedSub: Subscription;
  hasLoaded = false;

  constructor(protected _deviceService: DeviceService,
    protected _auth: AuthService,
    protected _contactApi: ContactApiService,
    protected _adminBuilder: AdminBuilderService,
    protected _translateService: TranslateService,
    protected _logAndMessage: LogAndMessageService,
    protected _tabService: TabService,
    protected _adminTableService: AdminTableService,
    private _fb: UntypedFormBuilder,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    private _confirmationService: ConfirmationService,
    private _authApi: AuthApiService) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit() {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.setupActiveTabChangedSubscription();
    this.form = this._fb.group({
      id: [null],
      name: [{value: null, disabled: true}, Validators.required],
      abbreviation: [{value: null, disabled: true}, Validators.required],
      enabled: [{value: false, disabled: true}],
      version: [null]
    });

    this.columns = COMPANY_TABLE_DEFINITION;

    const screenName$ = this._translateService.get('CONTACT.SCREEN.COMPANIES');
    const dialogHeader$ = this._translateService.get('CONTACT.LABEL.COMPANY_DETAILS');
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList(['CreateCompanies']);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList(['EditCompanies']);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList(['DeleteCompanies'])

    this.form.disable();
    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  handleLazyLoad(req, $event, filters) {
    this._adminTableService.setLastAdminLazyLoad(this.screenName, $event.lazy);
    const query = {
      name: null,
      enabled: null,
      abbreviation: null
    };

    if (filters.name) { query.name = filters.name.value; }
    if (filters.abbreviation) { query.abbreviation = filters.abbreviation.value; }
    if (filters.enabled && !isNullOrUndefined(filters.enabled.value)) {
      if (filters.enabled.value) {
        query.enabled = 'true';
      } else {
        query.enabled = 'false';
      }
    }

    this.queryNetwork(req, $event, query);
  }

  queryNetwork(req, $event, query): void {
    req.page += 1;
    const sort = this.buildNetworkSortQuery($event, 'name');
    this._contactApi.queryForCompanies(req.pageSize, req.page, query, sort).pipe(take(1)).subscribe(({ data }) => {
      const clone = Object.assign({}, data);
      this.companies  = clone.getCompanies.items;
      this.totalRecords = clone.getCompanies.length;
      this.elements = [...this.companies];
      this.hasLoaded = true;
    }, (error) => {
      this.loading = false;
      this._logAndMessage.errorLogOnly(error);
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'CONTACT.MESSAGES.ERROR.QUERY_COMPANIES',
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }, () => {
      this.loading = false;
      if (this.showSuccessMessage) {
        this.showSuccessMessage = false;
        this._logAndMessage.translateToSuccessMessage({
          bodyKey: 'CONTACT.MESSAGES.SUCCESS.QUERY_COMPANIES',
          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
        });
      }
    });
  }

  changeViewEvent($event): void {
    this.selectedCompany = $event.data;
    this.form.patchValue({
      id: $event.data.id,
      name: $event.data.name,
      abbreviation: $event.data.abbreviation,
      enabled: $event.data.enabled,
      version: $event.data.version,
    });
    this.showAudit = true;
    this.loadAuditHistory();
    this.displayDialog = true;

    if (this.canEdit) {
      this.form.enable();
    }
    else {
      this.form.disable();
    }
  }

  clearForm(): void {
    this.form.reset();
  }

  dialogClosed(): void {
    this.clearForm();
    this.selectedCompany = undefined;
    this.history = [];
    if (!isNullOrUndefined(this.audit)) {
      this.audit.closeTogglePanel();
    }
  }

  newCompany(): void {
    this.form.reset();
    this.history = [];
    this.showAudit = false;
    this.form.controls['enabled'].patchValue(false, { emitEvent: true });
    this.displayDialog = true;

    if (this.canCreate) {
      this.form.enable();
    }
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  nameChanged(): void {
    if (!isNullOrUndefined(this.selectedCompany)) {
      this.selectedCompany.name = this.form.controls['name'].value;
    }
  }

  abbrChanged(): void {
    if (!isNullOrUndefined(this.selectedCompany)) {
      this.selectedCompany.abbreviation = this.form.controls['abbreviation'].value;
    }
  }

  toggleRecord($event): void {
    if (!isNullOrUndefined($event) && !isNullOrUndefined(this.selectedCompany)) {
      this.selectedCompany.enabled = $event.checked;
    }
  }

  saveCompany(): void {
    if (!this.canEdit && !this.canCreate) {
      return;
    }

    if (!isNullOrUndefined(this.form) && this.form.valid) {
      let company = null;
      if (!isNullOrUndefined(this.selectedCompany)) {
        company = this.selectedCompany;
      } else {
        company = Object.assign({}, this.form.getRawValue());
      }
      if (!company.id) {
        this.createCompany(company);
      } else {
        this.updateCompany(company, true);
      }
    }
  }

  createCompany(company: Company) {
    if (!this.canCreate) {
      return;
    }

    company.dirtyStatus = DirtyStatus.NEW;
    const input: CompanyDataTableInput = this._adminBuilder.buildCompanyDataTableInput(company, true);

    this.saving = true;
    this._contactApi.createCompany(input).pipe(take(1)).subscribe((result) => {
      this.clearForm();
      this.displayDialog = false;
    }, (error) => {
      this.saving = false;
      this._logAndMessage.errorLogOnly(error);
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'CONTACT.MESSAGES.ERROR.CREATE_COMPANY',
        bodyParams: { name: company.name },
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }, () => {
      this.saving = false;
      this._logAndMessage.translateToSuccessMessage({
        bodyKey: 'CONTACT.MESSAGES.SUCCESS.CREATE_COMPANY',
        bodyParams: { name: company.name },
        headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
      });
      this.refresh();
    });
  }

  updateCompany(changes: Company, closeDialog: boolean) {
    if (!this.canEdit) {
      return;
    }

    changes.dirtyStatus = DirtyStatus.UPDATED;
    const companyChanges: CompanyDataTableInput = this._adminBuilder.buildCompanyDataTableInput(changes, false);

    this.saving = true;
    this._contactApi.updateCompany(changes.id, companyChanges).pipe(take(1)).subscribe((result) => {
      if (!closeDialog && !isNullOrUndefined(result)) {
        this.changeViewEvent({ data: result.data.updateCompany });
      } else {
        this.clearForm();
        this.displayDialog = false;
        this.refresh();
      }
    }, (error) => {
      this.saving = false;
      this._logAndMessage.errorLogOnly(error);
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'CONTACT.MESSAGES.ERROR.UPDATE_COMPANY',
        bodyParams: {name: changes.name},
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }, () => {
      this.saving = false;
      this._logAndMessage.translateToSuccessMessage({
        bodyKey: 'CONTACT.MESSAGES.SUCCESS.UPDATE_COMPANY',
        bodyParams: {name: changes.name},
        headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
      });
    });
  }

  deleteCompany(): void {
    if (!this.canDelete) {
      return;
    }
    if (!isNullOrUndefined(this.form)) {
      const company = Object.assign({}, this.form.getRawValue());
      let confHeader = null, ok = null, cancel = null, message = null;
      const confHeader$ = this._translateService.get('CONTACT.MESSAGES.HEADERS.DELETE_COMPANY');
      const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
      const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
      const message$ = this._translateService.get('CONTACT.MESSAGES.CONFIRMATION.DELETE_COMPANY', {name: company.name});

      forkJoin([confHeader$, ok$, cancel$, message$]).subscribe((messages) => {
        confHeader = messages[0];
        ok = messages[1];
        cancel = messages[2];
        message = messages[3];
      });

      this._confirmationService.confirm({
        header: confHeader,
        icon: 'fa fa-question-circle',
        acceptVisible: true,
        rejectVisible: true,
        acceptLabel: ok,
        rejectLabel: cancel,
        message: message,
        accept: () => {
          this.saving = true;
          if (!isNullOrUndefined(company.id)) { // Existing call type so delete it
            this._contactApi.allowCompanyDelete(company.id).pipe(take(1)).subscribe((canDelete) => {
              if (!isNullOrUndefined(canDelete) && !isNullOrUndefined(canDelete.data)
                && !isNullOrUndefined(canDelete.data.allowCompanyDelete) && !canDelete.data.allowCompanyDelete.value) {
                this.saving = false;
                this._logAndMessage.translateToErrorMessage({
                  bodyKey: "Delete cannot be performed, the entry is already associated with records.",//canDelete.data.allowCompanyDelete.message,
                  headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                });
              } else {
                this._contactApi.deleteCompany(company.id).pipe(take(1)).subscribe((result) => {
                  this.saving = false;
                  this.clearForm();
                  this.displayDialog = false;
                }, (error) => {
                  this._logAndMessage.errorLogOnly(error);
                  this._logAndMessage.translateToErrorMessage({
                    bodyKey: 'CONTACT.MESSAGES.ERROR.DELETE_COMPANY',
                    bodyParams: { name: company.name },
                    headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                  });
                }, () => {
                  this._logAndMessage.translateToSuccessMessage({
                    bodyKey: 'CONTACT.MESSAGES.SUCCESS.DELETE_COMPANY',
                    bodyParams: { name: company.name },
                    headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
                  });
                  this.refresh();
                });
              }
            });
          } else { // new call type so just close dialog
            this.displayDialog = false;
          }
        },
        reject: () => {

        }
      });
    }
  }

  loadAuditHistory(): void {
    this.auditLoading = true;
    this._contactApi.getCompanyHistory(this.selectedCompany.id).pipe(take(1)).subscribe(({ data }) => {
      const clone = Object.assign({}, data);
      this.history = [...buildAuditHistory(clone.getCompanyHistory)];
    }, (error) => {
      this._logAndMessage.errorLogOnly(error);
    }, () => {
      this.auditLoading = false;
    });
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub = TabService.getInstance().activeTabChanged.subscribe((tab) => {
      if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
        this._adminTableService.setLastLazyLoad(this._adminTableService.getLastAdminLazyLoad(this.screenName));
        this.refresh();
      }
    });
  }
}

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'apollo-client/util/Observable';
import { ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { TabService } from 'src/app/core/services/tab.service';
import { Contact } from 'src/app/model/contacts/contact';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { SearchContainerComponent } from '../../core/containers/search-container/search-container.component';
import {
  dynamicSort,
  isNullOrEmptyArray,
} from '../../core/functions/common-functions';
import { EmailGroup } from '../../model/admin/email-group';
import { EmailGroupInput } from '../../model/admin/email-group-input';
import { EmailGroupMember } from '../../model/admin/email-group-member';
import { DirtyStatus } from '../../model/common/dirty-status';
import { AdminApiService } from '../../services/admin-api.service';
import { AdminBuilderService } from '../../services/admin-builder.service';
import { AdminTableService } from '../../services/admin-table.service';
import { AuthService } from '../../services/auth.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { DeviceService } from '../../services/device.service';
import { LoadingService } from '../../services/loading.service';
import { LogAndMessageService } from '../../services/log-and-message.service';
import { EMAIL_GROUP_TABLE_DEFINITION } from '../definitions/email-group-table-definition';
import { BusinessUnit } from 'src/app/model/admin/business-unit';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { isNullOrUndefined } from 'src/app/utils/utils';
import { ListboxChangeEvent } from 'primeng/listbox';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
  selector: 'app-email-group-container',
  templateUrl: './email-group-container.component.html',
  styleUrls: ['./email-group-container.component.scss'],
})
export class EmailGroupContainerComponent
  extends SearchContainerComponent<EmailGroup>
  implements OnInit, OnDestroy
{
  @ViewChild('buFilter') businessUnitFilter: BusinessUnitFilterComponent;

  canCreate = false;
  canEdit = false;
  canDelete = false;
  saving = false;
  emailGroupForm: UntypedFormGroup;
  displayDialog = false;
  allContacts: any[];
  filteredContacts: any[];

  emailGroups: EmailGroup[] = [];
  members: EmailGroupMember[] = [];
  selectedGroup: EmailGroup;
  selectedMember: EmailGroupMember;
  contacts: any[];
  sub: Subscription;
  hasLoaded = false;

  availableBusinessUnits: BusinessUnit[] = [];
  sourcePicklist: BusinessUnit[] = [];
  targetPicklist: BusinessUnit[] = [];

  constructor(
    private _translateService: TranslateService,
    protected _deviceService: DeviceService,
    private _fb: UntypedFormBuilder,
    private _logger: LogAndMessageService,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    protected _auth: AuthService,
    private _adminTableService: AdminTableService,
    private _adminApi: AdminApiService,
    private _authApi: AuthApiService,
    private _adminBuilder: AdminBuilderService,
    private _contactApi: ContactApiService,
    private _contactBuilder: ContactBuilderService,
    private _confirmationService: ConfirmationService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit(): void {
    this.setTableService(this._adminTableService);
    this.emailGroupForm = this._fb.group({
      id: [null],
      name: [{ value: null, disabled: true }, Validators.required],
      enabled: [{ value: false, disabled: true }],
      contact: [{ value: null, disabled: true }],
      emailAddress: [{ value: null, disabled: true }],
      version: [null],
      businessUnits: [{ value: null, disabled: false }],
    });
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }

    this._contactApi
      .findContacts()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = [...data.getContacts].map((c) =>
          this._contactBuilder.buildContact(c)
        );
        this.allContacts = clone;
      });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  initialize(): void {
    this._translateService
      .get('ADMIN.SCREEN.EMAIL_GROUPS')
      .subscribe((label) => {
        TabService.getInstance().updateActiveTabLabel(label);
        this.screenName = label;
      });

    this._adminApi
      .getAvailableBusinessUnits()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = { ...data };
        this.availableBusinessUnits = clone.getAvailableBusinessUnits;
      });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateEmailGroups',
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      'EditEmailGroups',
    ]);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList([
      'DeleteEmailGroups',
    ]);
    this.emailGroupForm.disable();
    this.columns = EMAIL_GROUP_TABLE_DEFINITION;
    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  handleLazyLoad(req, $event, filters): void {
    const query = {
      name: null,
      enabled: null,
      businessUnits: null,
      businessUnitString: null,
    };

    let selectedBusinessUnits = [];
    if (this.businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = this.businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    query.businessUnits = selectedBusinessUnits;
    if (filters.name) {
      query.name = filters.name.value;
    }
    if (filters.enabled) {
      query.enabled = filters.enabled.value;
    }
    if (filters.businessUnitString) {
      query.businessUnitString = filters.businessUnitString.value;
    }

    this.queryNetwork(req, $event, query);
  }

  handleBusinessUnitChange(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  showMemberList(): boolean {
    if (isNullOrEmptyArray(this.members)) {
      return false;
    } else {
      return true;
    }
  }

  queryNetwork(req, $event, query): void {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._adminTableService.setLastSortField($event.lazy.sortField);
      this._adminTableService.setLastSortDirection($event.lazy.sortOrder);
    } else {
      sort['name'] = 'ASC';
      this._adminTableService.setLastSortField('name');
      this._adminTableService.setLastSortDirection(1);
    }
    this._adminApi
      .queryForEmailGroups(req.pageSize, req.page, query, sort)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = Object.assign({}, data);
          for (let i = 0; i < clone.getEmailGroups.length; ++i) {
            let businessUnitsString = '';
            for (
              let j = 0;
              j < clone.getEmailGroups[i].businessUnits.length;
              ++j
            ) {
              businessUnitsString +=
                clone.getEmailGroups[i].businessUnits[j].name;
              if (j + 1 < clone.getEmailGroups[i].businessUnits.length)
                businessUnitsString += ', ';
            }
            clone.getEmailGroups[i].businessUnitString = businessUnitsString;
          }
          this.emailGroups = clone.getEmailGroups;
          this.totalRecords = clone.getEmailGroups.length;
          this.elements = [...this.emailGroups];
          this.loading = false;
          this.hasLoaded = true;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  newEmailGroup(): void {
    this.emailGroupForm.reset();
    this.emailGroupForm.controls['enabled'].patchValue(false, {
      emitEvent: true,
    });
    this.emailGroupForm.controls['contact'].disable();
    this.emailGroupForm.controls['emailAddress'].disable();
    this.members = [];
    this.sourcePicklist = [];
    this.targetPicklist = [];
    this.availableBusinessUnits.forEach((x) => {
      this.sourcePicklist.push(x);
    });

    if (this.canCreate) {
      this.emailGroupForm.enable();
    } else {
      this.emailGroupForm.disable();
    }
    this.displayDialog = true;
  }

  saveEmailGroup(): void {
    if (!this.canEdit && !this.canCreate) {
      return;
    }

    if (!isNullOrUndefined(this.emailGroupForm) && this.emailGroupForm.valid) {
      let emailGroup = null;
      if (!isNullOrUndefined(this.selectedGroup)) {
        emailGroup = this.selectedGroup;
      } else {
        emailGroup = Object.assign({}, this.emailGroupForm.getRawValue());
      }
      if (!emailGroup.id) {
        this.createEmailGroup(emailGroup);
      } else {
        this.updateEmailGroup(emailGroup, true);
      }
    }
  }

  createEmailGroup(emailGroup): void {
    if (!this.canCreate) {
      return;
    }

    emailGroup.dirtyStatus = DirtyStatus.NEW;
    const emailGroupInput: EmailGroupInput =
      this._adminBuilder.buildEmailGroupInput(emailGroup, this.targetPicklist);

    this.saving = true;
    this._adminApi
      .createEmailGroup(emailGroupInput)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.clearForm();
          this.displayDialog = false;
          this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
        },
        (error) => {
          this.saving = false;
          console.log('Error creating email group', error);
        },
        () => {
          this.saving = false;
        }
      );
  }

  updateEmailGroup(emailGroup: EmailGroup, closeDialog: boolean): void {
    if (!this.canEdit) {
      return;
    }
    emailGroup.dirtyStatus = DirtyStatus.UPDATED;
    const emailGroupInput: EmailGroupInput =
      this._adminBuilder.buildEmailGroupInput(emailGroup, this.targetPicklist);
    this.saving = true;
    this._adminApi
      .updateEmailGroup(emailGroupInput.id, emailGroupInput)
      .pipe(take(1))
      .subscribe(
        (result: any) => {
          if (!closeDialog && !isNullOrUndefined(result)) {
            this.changeViewEvent({ data: result.data.updateEmailGroup });
          } else {
            this.clearForm();
            this.displayDialog = false;
          }
        },
        (error) => {
          this.saving = false;
          console.log('Error updating email group', error);
        },
        () => {
          // Reset these if they had something in them
          this.emailGroupForm.controls['contact'].patchValue(null, {
            emitEvent: true,
          });
          this.emailGroupForm.controls['emailAddress'].patchValue(null, {
            emitEvent: true,
          });
          this.saving = false;
        }
      );
  }

  deleteEmailGroup(): void {
    if (!this.canDelete) {
      return;
    }

    if (!isNullOrUndefined(this.emailGroupForm)) {
      const emailGroup = Object.assign({}, this.emailGroupForm.getRawValue());
      let confHeader = null,
        ok = null,
        cancel = null,
        message = null;
      const confHeader$ = this._translateService.get(
        'ADMIN.MESSAGES.HEADERS.DELETE_GROUP'
      );
      const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
      const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
      const message$ = this._translateService.get(
        'ADMIN.MESSAGES.CONFIRMATION.DELETE_GROUP',
        { name: emailGroup.name }
      );

      forkJoin([confHeader$, ok$, cancel$, message$]).subscribe((messages) => {
        confHeader = messages[0];
        ok = messages[1];
        cancel = messages[2];
        message = messages[3];
      });
      this._confirmationService.confirm({
        header: confHeader,
        icon: 'fa fa-question-circle',
        acceptVisible: true,
        rejectVisible: true,
        acceptLabel: ok,
        rejectLabel: cancel,
        message: message,
        accept: () => {
          this.saving = true;
          if (!isNullOrUndefined(emailGroup.id)) {
            // Existing group so delete it
            this._adminApi
              .deleteEmailGroup(emailGroup.id)
              .pipe(take(1))
              .subscribe(
                (result) => {
                  this.saving = false;
                  this.clearForm();
                  this.displayDialog = false;
                },
                (error) => {
                  console.log('Error deleting email group', error);
                }
              );
          } else {
            // Group is new, so just close the dialog
            this.displayDialog = false;
          }
        },
        reject: () => {},
      });
    }
  }

  clearForm(): void {
    this.emailGroupForm.reset();
  }

  dialogClosed(): void {
    this.clearForm();
    this.selectedGroup = undefined;
    this.selectedMember = undefined;
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  changeViewEvent($event): void {
    this.selectedGroup = $event.data;
    this.emailGroupForm.patchValue({
      id: $event.data.id,
      name: $event.data.name,
      enabled: $event.data.enabled,
      version: $event.data.version,
      businessUnits: $event.data.businessUnits,
    });
    if (!isNullOrUndefined($event.data.id) && this.canEdit === true) {
      this.emailGroupForm.controls['emailAddress'].enable();
      this.emailGroupForm.controls['contact'].enable();
    }
    const businessUnits = this.emailGroupForm.controls['businessUnits'].value;
    if (businessUnits != null) {
      let currentBusinessUnits = [];
      businessUnits.forEach((x) => {
        currentBusinessUnits.push(x.name);
      });
      this.sourcePicklist = [];
      this.targetPicklist = [];
      this.availableBusinessUnits.forEach((x) => {
        if (currentBusinessUnits.includes(x.name)) {
          this.targetPicklist.push(x);
        } else {
          this.sourcePicklist.push(x);
        }
      });
    }
    this.members = [...$event.data.EmailGroupMembers];

    if (this.canEdit) {
      this.emailGroupForm.enable();
    } else {
      this.emailGroupForm.disable();
    }
    this.displayDialog = true;
  }

  memberSelected($event: ListboxChangeEvent): void {
    if (!isNullOrUndefined($event) && !isNullOrUndefined($event.value)) {
      this.selectedMember = $event.value;
    }
  }

  memberSearch($event: AutoCompleteCompleteEvent): void {
    const query = $event && $event.query ? $event.query.toLowerCase() : '';
    this.contacts = this.allContacts
      .sort(dynamicSort('firstName', 1))
      .filter((c) =>
        (c.firstName + ' ' + c.lastName).toLowerCase().startsWith(query)
      )
      .map((c: Contact) => this._adminBuilder.buildEmailGroupContact(c));
  }

  contactSelected($event: AutoCompleteSelectEvent): void {
    const eventValue = $event.value;
    if (!isNullOrUndefined(eventValue)) {
      this.emailGroupForm.controls['emailAddress'].patchValue(
        eventValue.address
      );
    }
  }

  addEmailGroupMember(): void {
    const emailGroup = this.emailGroupForm.getRawValue();
    if (
      !isNullOrUndefined(emailGroup) &&
      !isNullOrUndefined(emailGroup.emailAddress)
    ) {
      const nm: EmailGroupMember = this._adminBuilder.buildNewEmailGroupMember(
        emailGroup.emailAddress
      );
      this.selectedGroup = Object.assign({}, emailGroup);
      this.selectedGroup.EmailGroupMembers = this.members;
      if (!isNullOrUndefined(this.selectedGroup)) {
        this.selectedGroup.EmailGroupMembers = [
          ...this.selectedGroup.EmailGroupMembers,
          nm,
        ];
        this.updateEmailGroup(this.selectedGroup, false);
      }
    } else {
      this._logger.translateToErrorMessage({
        headerKey: 'ADMIN.MESSAGES.HEADERS.ADD_MEMBER',
        bodyKey: 'ADMIN.MESSAGES.ERROR.MISSING_EMAIL_ADDRESS',
      });
    }
  }

  deleteEmailGroupMember(): void {
    const emailGroup = this.emailGroupForm.getRawValue();
    if (
      !isNullOrUndefined(emailGroup) &&
      !isNullOrUndefined(this.selectedMember)
    ) {
      this.selectedMember.dirtyStatus = DirtyStatus.DELETED;
      for (let i = 0; i < this.selectedGroup.EmailGroupMembers.length; i++) {
        if (
          this.selectedGroup.EmailGroupMembers[i].id == this.selectedMember.id
        ) {
          this.selectedGroup.EmailGroupMembers.splice(i, 1);
        }
      }
      if (!isNullOrUndefined(this.selectedGroup)) {
        this.updateEmailGroup(this.selectedGroup, false);
      }
    } else {
      this._logger.translateToErrorMessage({
        headerKey: 'ADMIN.MESSAGES.HEADERS.REMOVE_MEMBER',
        bodyKey: 'ADMIN.MESSAGES.ERROR.NO_EMAIL_SELECTED',
      });
    }
  }

  toggleGroup($event: CheckboxChangeEvent): void {
    if (!isNullOrUndefined($event) && !isNullOrUndefined(this.selectedGroup)) {
      this.selectedGroup.enabled = $event.checked;
    }
  }

  nameChanged(): void {
    if (!isNullOrUndefined(this.selectedGroup)) {
      this.selectedGroup.name = this.emailGroupForm.controls['name'].value;
    }
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }
}

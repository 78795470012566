import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuditDialogContainerComponent } from 'src/app/core/containers/audit-dialog-container.component';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { buildAuditHistory } from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { MOCEventCatInput } from 'src/app/model/admin/moc-event-cat-input';
import { Audit } from 'src/app/model/common/audit';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { MOCEventCategory } from 'src/app/model/crm/moc-event-category';
import { AdminBuilderService } from 'src/app/services/admin-builder.service';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { MocEventApiService } from 'src/app/services/moc-event-api.service';
import { MOC_EVENT_CATEGORY_TABLE_DEFINITION } from '../definitions/data-table-definitions';
import { isValidMocEventCategoryInput } from 'src/app/crm/functions/moc-event-functions';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { ShiftTurnoverApiService } from 'src/app/services/shift-turnover-api.service';
import { BusinessUnit } from 'src/app/model/admin/business-unit';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { isNullOrUndefined } from 'src/app/utils/utils';

@Component({
  selector: 'app-moc-event-category-container',
  templateUrl: './moc-event-category-container.component.html',
  styleUrls: ['./moc-event-category-container.component.scss'],
})
export class MocEventCategoryContainerComponent
  extends SearchContainerComponent<MOCEventCategory>
  implements OnInit, OnDestroy
{
  @ViewChild('audit', { static: false }) audit: AuditDialogContainerComponent;
  @ViewChild('buFilter') businessUnitFilter: BusinessUnitFilterComponent;

  canCreate = false;
  canEdit = false;
  canDelete = false;
  saving = false;
  form: UntypedFormGroup;
  displayDialog = false;
  dialogHeader: string;
  groups: SelectItem[];

  categories: MOCEventCategory[] = [];
  selectedCategory: MOCEventCategory;
  showSuccessMessage = true;
  sub: Subscription;

  auditLoading = false;
  showAudit = true;
  displayAuditDialog = false;
  history: Audit[];

  activeTabChangedSub: Subscription;
  hasLoaded = false;

  availableBusinessUnits: BusinessUnit[] = [];
  sourcePicklist: BusinessUnit[] = [];
  targetPicklist: BusinessUnit[] = [];

  constructor(
    protected _deviceService: DeviceService,
    protected _auth: AuthService,
    protected _mocApi: MocEventApiService,
    protected _adminBuilder: AdminBuilderService,
    protected _translateService: TranslateService,
    protected _logAndMessage: LogAndMessageService,
    protected _tabService: TabService,
    protected _adminTableService: AdminTableService,
    private _fb: UntypedFormBuilder,
    private _adminApi: AdminApiService,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    private _confirmationService: ConfirmationService,
    private _authApi: AuthApiService,
    private _shiftApi: ShiftTurnoverApiService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit() {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.setupActiveTabChangedSubscription();
    this.form = this._fb.group({
      id: [null],
      name: [{ value: null, disabled: false }, Validators.required],
      enabled: [{ value: false, disabled: false }],
      version: [null],
      businessUnits: [{ value: null, disabled: false }],
    });

    this.columns = MOC_EVENT_CATEGORY_TABLE_DEFINITION;

    const screenName$ = this._translateService.get(
      'CRM.SCREEN.MOC_EVENT_CATEGORIES'
    );
    const dialogHeader$ = this._translateService.get(
      'CRM.LABEL.MOC_EVENT_CATEGORY_DETAILS'
    );
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });

    this._adminApi
      .getAvailableBusinessUnits()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = { ...data };
        this.availableBusinessUnits = clone.getAvailableBusinessUnits;
      });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateMOCEventCategories',
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      'EditMOCEventCategories',
    ]);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList([
      'DeleteMOCEventCategories',
    ]);
    this.form.disable();

    this._shiftApi
      .loadAvailableGroups()
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.groups = [
          { label: 'Choose', value: null },
          ...data.getShiftGroups.map((sg) => ({
            label: sg.name,
            value: sg.id,
          })),
        ];
        this.applyPreviousData();
        this.applyPreviousFilters();
      });
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  handleLazyLoad(req, $event, filters) {
    this._adminTableService.setLastAdminLazyLoad(this.screenName, $event.lazy);
    const query = {
      name: null,
      enabled: null,
      businessUnits: null,
      businessUnitString: null,
    };

    let selectedBusinessUnits = [];
    if (this.businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = this.businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    query.businessUnits = selectedBusinessUnits;
    if (filters.name) {
      query.name = filters.name.value;
    }
    if (filters.enabled && !isNullOrUndefined(filters.enabled.value)) {
      if (filters.enabled.value) {
        query.enabled = 'true';
      } else {
        query.enabled = 'false';
      }
    }
    if (filters.businessUnitString) {
      query.businessUnitString = filters.businessUnitString.value;
    }

    this.queryNetwork(req, $event, query);
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  queryNetwork(req, $event, query): void {
    req.page += 1;
    const sort = this.buildNetworkSortQuery($event, 'name');
    console.log(query);
    this._mocApi
      .queryForMocEventCategories(req.pageSize, req.page, query, sort)
      .pipe(take(1))
      .subscribe({
        next: ({ data }) => {
          const clone = Object.assign({}, data);
          for (let i = 0; i < clone.getMOCEventCategories.items.length; ++i) {
            let businessUnitsString = '';
            for (
              let j = 0;
              j < clone.getMOCEventCategories.items[i].businessUnits.length;
              ++j
            ) {
              businessUnitsString +=
                clone.getMOCEventCategories.items[i].businessUnits[j].name;
              if (
                j + 1 <
                clone.getMOCEventCategories.items[i].businessUnits.length
              )
                businessUnitsString += ', ';
            }
            clone.getMOCEventCategories.items[i].businessUnitString =
              businessUnitsString;
          }
          this.categories = clone.getMOCEventCategories.items;
          this.totalRecords = clone.getMOCEventCategories.totalRecords;
          this.elements = [...this.categories];
          this.hasLoaded = true;
        },
        error: (error) => {
          this.loading = false;
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'CRM.MESSAGES.ERROR.QUERY_MOC_EVENT_CATEGORIES',
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        },
        complete: () => {
          this.loading = false;
          if (this.showSuccessMessage) {
            this.showSuccessMessage = false;
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'CRM.MESSAGES.SUCCESS.QUERY_MOC_EVENT_CATEGORIES',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
            });
          }
        },
      });
  }

  changeViewEvent($event): void {
    this.selectedCategory = $event.data;
    this.form.patchValue({
      id: $event.data.id,
      name: $event.data.name,
      // shiftGroup: ($event.data.ShiftGroup ? $event.data.ShiftGroup.id : null), // DEFault to gas control
      enabled: $event.data.enabled,
      version: $event.data.version,
      businessUnits: $event.data.businessUnits,
    });
    this.showAudit = true;
    this.loadAuditHistory();

    const businessUnits = this.form.controls['businessUnits'].value;
    let currentBusinessUnits = [];
    businessUnits.forEach((x) => {
      currentBusinessUnits.push(x.name);
    });
    this.sourcePicklist = [];
    this.targetPicklist = [];
    this.availableBusinessUnits.forEach((x) => {
      if (currentBusinessUnits.includes(x.name)) {
        this.targetPicklist.push(x);
      } else {
        this.sourcePicklist.push(x);
      }
    });

    if (this.canEdit) {
      this.form.enable();
    } else {
      this.form.disable();
    }
    this.displayDialog = true;
  }

  clearForm(): void {
    this.form.reset();
  }

  dialogClosed(): void {
    this.clearForm();
    this.selectedCategory = undefined;
    this.history = [];
    if (this.audit) {
      this.audit.closeTogglePanel();
    }
  }

  newMocEventCategory(): void {
    this.form.reset();
    this.history = [];
    this.showAudit = false;
    this.form.controls['enabled'].patchValue(false, { emitEvent: true });
    this.sourcePicklist = [];
    this.targetPicklist = [];
    this.availableBusinessUnits.forEach((x) => {
      this.sourcePicklist.push(x);
    });

    if (this.canCreate) {
      this.form.enable();
    } else {
      this.form.disable();
    }
    this.displayDialog = true;
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  nameChanged(): void {
    if (!isNullOrUndefined(this.selectedCategory)) {
      this.selectedCategory.name = this.form.controls['name'].value;
    }
  }

  groupChanged($event): void {
    if (
      !isNullOrUndefined($event) &&
      !isNullOrUndefined($event.value) &&
      !isNullOrUndefined(this.groups)
    ) {
      const shiftGroup = this.groups.find((sg) => sg.value === $event.value);
      if (
        !isNullOrUndefined(this.selectedCategory) &&
        !isNullOrUndefined(shiftGroup)
      ) {
        this.selectedCategory.ShiftGroup = {
          id: shiftGroup.value,
          name: shiftGroup.label,
        };
      }
    }
  }

  toggleRecord($event): void {
    if (
      !isNullOrUndefined($event) &&
      !isNullOrUndefined(this.selectedCategory)
    ) {
      this.selectedCategory.enabled = $event.checked;
    }
  }

  saveMocEventCategory(): void {
    if (!(this.canCreate || this.canEdit)) {
      return;
    }

    if (!isNullOrUndefined(this.form) && this.form.valid) {
      let category = null;
      if (!isNullOrUndefined(this.selectedCategory)) {
        category = this.selectedCategory;
      } else {
        category = Object.assign({}, this.form.getRawValue());
      }
      if (!category.id) {
        this.createMocEventCategory(category);
      } else {
        this.updateMocEventCategory(category, true);
      }
    }
  }

  createMocEventCategory(category: MOCEventCategory) {
    if (!this.canCreate) {
      this._logAndMessage.error(
        'Cannot create moc event category!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }

    const shiftGroup = this.groups.find(
      (sg) => sg.label === 'Gas Control Room'
    );
    category.dirtyStatus = DirtyStatus.NEW;
    const categoryInput: MOCEventCatInput =
      this._adminBuilder.buildMOCEventCatInput(
        category,
        true,
        this.targetPicklist
      );
    if (shiftGroup) {
      categoryInput['shiftGroupId'] = shiftGroup.value;
    }
    const isValid = isValidMocEventCategoryInput(
      categoryInput,
      this.categories
    );
    if (!isNullOrUndefined(isValid)) {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        bodyKey: isValid,
      });
      return;
    }
    this.saving = true;
    this._mocApi
      .createMocEventCategory(categoryInput)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.clearForm();
          this.displayDialog = false;
        },
        (error) => {
          this.saving = false;
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'CRM.MESSAGES.ERROR.CREATE_MOC_EVENT_CATEGORY',
            bodyParams: { name: category.name },
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        },
        () => {
          this.saving = false;
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'CRM.MESSAGES.SUCCESS.CREATE_MOC_EVENT_CATEGORY',
            bodyParams: { name: category.name },
            headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          });
          this._mocApi.loadAvailableMocEventCategories();
          this.refresh();
        }
      );
  }

  updateMocEventCategory(changes: MOCEventCategory, closeDialog: boolean) {
    if (!this.canEdit) {
      this._logAndMessage.error(
        'Cannot edit moc event category!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }

    const shiftGroup = this.groups.find(
      (sg) => sg.label === 'Gas Control Room'
    );
    changes.dirtyStatus = DirtyStatus.UPDATED;
    const categoryChanges: MOCEventCatInput =
      this._adminBuilder.buildMOCEventCatInput(
        changes,
        false,
        this.targetPicklist
      );
    if (shiftGroup) {
      categoryChanges['shiftGroupId'] = shiftGroup.value;
    }
    const isValid = isValidMocEventCategoryInput(
      categoryChanges,
      this.categories
    );
    if (!isNullOrUndefined(isValid)) {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        bodyKey: isValid,
      });
      return;
    }
    this.saving = true;
    this._mocApi
      .updateMocEventCategory(changes.id, categoryChanges)
      .pipe(take(1))
      .subscribe(
        (result) => {
          if (!closeDialog && !isNullOrUndefined(result)) {
            this.changeViewEvent({ data: result.data.updateMOCEventCategory });
          } else {
            this.clearForm();
            this.displayDialog = false;
          }
        },
        (error) => {
          this.saving = false;
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'CRM.MESSAGES.ERROR.UPDATE_MOC_EVENT_CATEGORY',
            bodyParams: { name: changes.name },
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        },
        () => {
          this.saving = false;
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'CRM.MESSAGES.SUCCESS.UPDATE_MOC_EVENT_CATEGORY',
            bodyParams: { name: changes.name },
            headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          });
          this._mocApi.loadAvailableMocEventCategories();
          this.refresh();
        }
      );
  }

  deleteMocEventCategory(): void {
    if (!this.canDelete) {
      this._logAndMessage.error(
        'Cannot delete moc event category!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }

    if (!isNullOrUndefined(this.form)) {
      const category = Object.assign({}, this.form.getRawValue());
      let confHeader = null,
        ok = null,
        cancel = null,
        message = null;
      const confHeader$ = this._translateService.get(
        'CRM.MESSAGES.HEADERS.DELETE_MOC_EVENT_CATEGORY'
      );
      const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
      const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
      const message$ = this._translateService.get(
        'CRM.MESSAGES.CONFIRMATION.DELETE_MOC_EVENT_CATEGORY'
      );

      forkJoin([confHeader$, ok$, cancel$, message$]).subscribe((messages) => {
        confHeader = messages[0];
        ok = messages[1];
        cancel = messages[2];
        message = messages[3];
      });

      this._confirmationService.confirm({
        header: confHeader,
        icon: 'fa fa-question-circle',
        acceptVisible: true,
        rejectVisible: true,
        acceptLabel: ok,
        rejectLabel: cancel,
        message: message,
        accept: () => {
          this.saving = true;
          if (!isNullOrUndefined(category.id)) {
            // Existing ShiftMitigation so delete it
            this._mocApi
              .allowMocEventCategoryDelete(category.id)
              .pipe(take(1))
              .subscribe((canDelete) => {
                if (
                  !isNullOrUndefined(canDelete) &&
                  !isNullOrUndefined(canDelete.data) &&
                  !isNullOrUndefined(
                    canDelete.data.allowMOCEventCategoryDelete
                  ) &&
                  !canDelete.data.allowMOCEventCategoryDelete.value
                ) {
                  this.saving = false;
                  this._logAndMessage.translateToErrorMessage({
                    bodyKey: canDelete.data.allowMOCEventCategoryDelete.message,
                    headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                  });
                } else {
                  this._mocApi
                    .deleteMocEventCategory(category.id)
                    .pipe(take(1))
                    .subscribe(
                      (result) => {
                        this.saving = false;
                        this.clearForm();
                        this.displayDialog = false;
                        this.refresh();
                      },
                      (error) => {
                        this._logAndMessage.errorLogOnly(error);
                        this._logAndMessage.translateToErrorMessage({
                          bodyKey:
                            'CRM.MESSAGES.ERROR.DELETE_MOC_EVENT_CATEGORY',
                          bodyParams: { name: category.name },
                          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                        });
                      },
                      () => {
                        this._logAndMessage.translateToSuccessMessage({
                          bodyKey:
                            'CRM.MESSAGES.SUCCESS.DELETE_MOC_EVENT_CATEGORY',
                          bodyParams: { name: category.name },
                          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
                        });
                        this._mocApi.loadAvailableMocEventCategories();
                      }
                    );
                }
              });
          } else {
            // new ShiftMitigation so just close dialog
            this.displayDialog = false;
          }
        },
        reject: () => {},
      });
    }
  }

  loadAuditHistory(): void {
    this.auditLoading = true;
    this._mocApi
      .getMocEventCategoryHistory(this.selectedCategory.id)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = Object.assign({}, data);
          this.history = [
            ...buildAuditHistory(clone.getMOCEventCategoryHistory),
          ];
        },
        (error) => {
          this._logAndMessage.errorLogOnly(error);
        },
        () => {
          this.auditLoading = false;
        }
      );
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub =
      TabService.getInstance().activeTabChanged.subscribe((tab) => {
        if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
          this._adminTableService.setLastLazyLoad(
            this._adminTableService.getLastAdminLazyLoad(this.screenName)
          );
          this.refresh();
        }
      });
  }
}

import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuditDialogContainerComponent } from 'src/app/core/containers/audit-dialog-container.component';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { Role } from 'src/app/model/admin/role';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { AdminBuilderService } from 'src/app/services/admin-builder.service';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { ROLES_CLAIMS_TABLE_DEFINITION } from 'src/app/admin/definitions/data-table-definitions';
import { Claim } from 'src/app/model/admin/claim';
import { UpdateRoleInput } from 'src/app/model/admin/update-role-input';
import { isNullOrUndefined, sortByProperty } from 'src/app/utils/utils';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { PickList } from 'primeng/picklist';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-role-claim-assign-container',
  templateUrl: './role-claim-assign-container.component.html',
  styleUrls: ['./role-claim-assign-container.component.scss'],
})
export class RoleClaimAssignContainerComponent
  extends SearchContainerComponent<Role>
  implements OnInit, OnDestroy
{
  @ViewChild('audit', { static: false }) audit: AuditDialogContainerComponent;
  @ViewChild('pickList', { static: false }) pickList: PickList;

  canEdit = false;
  saving = false;
  roleClaimForm: UntypedFormGroup;
  displayDialog = false;
  dialogHeader: string;
  filteredContacts: any[];

  rolesAndClaims: any[];
  roles: Role[] = [];
  selectedRole: Role;
  showSuccessMessage = true;
  hasLoaded = false;

  activeTabChangedSub: Subscription;

  availableClaims: Claim[] = [];
  sourcePicklist: Claim[] = [];
  targetPicklist: Claim[] = [];

  constructor(
    protected _deviceService: DeviceService,
    protected _auth: AuthService,
    protected _adminBuilder: AdminBuilderService,
    private _adminApi: AdminApiService,
    protected _translateService: TranslateService,
    protected _logAndMessage: LogAndMessageService,
    protected _tabService: TabService,
    protected _adminTableService: AdminTableService,
    private _fb: UntypedFormBuilder,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    private _authApi: AuthApiService,
    private _confirmationService: ConfirmationService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit() {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.roleClaimForm = this._fb.group({
      id: [null],
      name: [{ value: null, disabled: true }, Validators.required],
      claims: [{ value: false, disabled: true }],
      version: [null],
    });

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  initialize() {
    const screenName$ = this._translateService.get(
      'ROLE.SCREEN.ROLE_CLAIM_ASSIGN'
    );
    const dialogHeader$ = this._translateService.get('ROLE.LABEL.ROLE_DETAILS');
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });

    this._adminApi
      .getAvailableClaims()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = { ...data };
        this.availableClaims = clone.getAvailableClaims;
      });

    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList(['EditRoles']);
    if (this.canEdit) {
      this.roleClaimForm.enable();
    }

    this.columns = ROLES_CLAIMS_TABLE_DEFINITION;
    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  handleLazyLoad(req, $event, filters) {
    const query = {
      role: null,
      claim: null,
    };

    if (filters.name) {
      query.role = filters.name.value;
    }
    if (filters.claims) {
      query.claim = filters.claims.value;
    }

    this.queryNetwork(req, $event, query);
  }

  queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
    } else {
      sort['role'] = 'ASC';
    }

    this._adminTableService.setLastSortField('role');
    this._adminTableService.setLastSortDirection(1);

    this._adminApi
      .queryForRolesAndClaims(req.pageSize, req.page, query, sort)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = Object.assign({}, data);
          this.rolesAndClaims = clone.queryForRolesAndClaims.items;
          this.totalRecords = clone.queryForRolesAndClaims.totalRecords;
          this.elements = [...this.rolesAndClaims];
          this.loading = false;
          this.hasLoaded = true;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  //Double clicking on table row action:
  changeViewEvent($event): void {
    this.selectedRole = $event.data;
    this.roleClaimForm.patchValue({
      id: $event.data.id,
      name: $event.data.name,
      claims: $event.data.claims,
      version: $event.data.version,
    });

    // Ensure that the name field is not editable
    this.roleClaimForm.controls['name'].disable();

    // update the sourceClaims
    // update the targetClaims
    this.sourcePicklist = [];
    this.targetPicklist = [];
    const roleClaims =
      this.roleClaimForm.controls['claims'].value
        ?.split(',')
        .map((x) => x.trim()) ?? [];

    this.availableClaims.forEach((x) => {
      if (roleClaims.includes(x.name)) {
        this.targetPicklist.push(x);
      } else {
        this.sourcePicklist.push(x);
      }
    });

    this.sourcePicklist.sort((a, b) => sortByProperty(a, b, 'name'));
    this.targetPicklist.sort((a, b) => sortByProperty(a, b, 'name'));
    this.displayDialog = true;
  }

  clearForm(): void {
    this.roleClaimForm.reset();
    this.pickList.resetSourceFilter();
    this.pickList.resetTargetFilter();
  }

  dialogClosed(): void {
    this.clearForm();
    this.selectedRole = undefined;
    if (!isNullOrUndefined(this.audit)) {
      this.audit.closeTogglePanel();
    }
  }

  //refresh button in top right
  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() }); //this triggers infinite loop
  }

  //Save button in dialog popup
  saveRole(): void {
    if (!this.canEdit) {
      return;
    }

    if (!isNullOrUndefined(this.roleClaimForm) && this.roleClaimForm.valid) {
      const updateRoleInput: UpdateRoleInput =
        this._adminBuilder.buildRoleClaimInput(
          this.roleClaimForm.getRawValue(),
          this.targetPicklist
        );
      this.saving = true;
      this._adminApi
        .updateRole(updateRoleInput.id, updateRoleInput)
        .pipe(take(1))
        .subscribe(
          (result: any) => {
            var updatedRole = this.updateRolesAndClaims(result.data.updateRole);
            this.changeViewEvent({ data: updatedRole });
            this.clearForm();
            this.displayDialog = false;
            this.saving = false;
          },
          (error) => {
            this.saving = false;
            console.error('Error updating role.', error);
          }
        );
    }
  }

  updateRolesAndClaims(role): Role {
    // find the role in the list
    var index = this.rolesAndClaims.findIndex((x) => x.id == role.id);
    this.rolesAndClaims[index].claims = role.claims
      .map((c) => c.name)
      .join(', ');
    return this.rolesAndClaims[index];
  }

  handleCopyClaimsChange($event) {
    this._translateService.get(
      'ADMIN.MESSAGES.CONFIRMATION.COPY_CLAIMS',
      { source: $event.value.name, destination: this.selectedRole.name }
    ).subscribe((message) => {
      this._confirmationService.confirm({
        message: message,
        key: 'copy-claims',
        accept: () => {
          this.saving = true;
          this._adminApi
            .copyClaims($event.value.id, this.selectedRole.id)
            .subscribe(
              (result: Role) => {
                var updatedRole = this.updateRolesAndClaims(result);
                this.changeViewEvent({ data: updatedRole });
                this.clearForm();
                this.displayDialog = false;
                this.saving = false;
              },
              (error) => {
                this.saving = false;
                console.error('Error copying claims.', error);
              }
            );
        },
      });
   });
  }
}

<div id="claim-assign-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  <div *ngIf="ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])">
    <div style="text-align: right">
      <div style="text-align: right">
        <app-progress-spinner [enabled]="saving"></app-progress-spinner>
      </div>
    </div>
  </div>
  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
  <div class="content">
    <div class="button-wrapper group content-header">
      <button
        pButton
        class="refresh-button width-twenty"
        icon="fa fa-fw fa-refresh"
        (click)="refresh()"
      ></button>
    </div>

    <div
      [style.display]="!elements ? 'none' : ''"
      *ngIf="ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])"
    >
      <app-general-grid
        [values]="elements"
        [columns]="columns"
        [loading]="loading"
        [deviceSize]="getDeviceSize()"
        [totalRecords]="totalRecords"
        [filterable]="true"
        [screenName]="screenName"
        [rows]="rows"
        [lazy]="true"
        (lazyLoad)="lazyLoad($event)"
        [includeExportCSV]="false"
        (changeViewEvent)="changeViewEvent($event)"
        scrollHeight="75vh"
        [usePaginator]="false"
        [alwaysShowPaginator]="false"
        [rowsPerPageOptions]="[50, 100, 200]"
        #grid
      >
      </app-general-grid>
    </div>

    <p-dialog
      [header]="dialogHeader"
      [(visible)]="displayDialog"
      showEffect="fade"
      [modal]="true"
      (onHide)="dialogClosed()"
      [transitionOptions]="'0ms'"
      [style]="{ width: '1000px' }"
    >
      <form [formGroup]="roleClaimForm">
        <div>
          <div class="no-padding modal-input">
            <div class="label">{{ "ADMIN.LABEL.NAME" | translate }}:</div>
            <div>
              <input type="text" pInputText formControlName="name" />
            </div>
          </div>
          <div class="copy-dropdown">
            <div class="label">{{ "ADMIN.LABEL.COPY" | translate }}:</div>
            <p-dropdown
              optionLabel="name"
              [options]="rolesAndClaims"
              (onChange)="handleCopyClaimsChange($event)"
              placeholder="Select a Role"
              [style]="{ width: '23vw' }"
            >
            </p-dropdown>
          </div>
          <div style="width: 100%">
            <p-pickList
              [source]="sourcePicklist"
              [target]="targetPicklist"
              targetHeader="Assigned Claims"
              [dragdrop]="false"
              sourceHeader="Available Claims"
              [responsive]="true"
              [showSourceControls]="false"
              [showTargetControls]="false"
              [sourceStyle]="{
                height: '400px',
                'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
              }"
              [targetStyle]="{
                height: '400px',
                'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
              }"
              filterBy="name"
              #pickList
            >
              <ng-template let-claim pTemplate="item">
                <div>
                  <div>
                    <h5>{{ claim.name }}</h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
          <div style="margin-top: 8px">
            <div class="button-wrapper" *ngIf="canEdit">
              <button
                pButton
                label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                icon="fa fa-fw fa-save"
                (click)="saveRole()"
                [disabled]="!this.roleClaimForm.valid"
              ></button>
            </div>
          </div>
        </div>
      </form>
    </p-dialog>
  </div>

  <div *ngIf="ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])">
    <p-message
      severity="info"
      text="{{ 'COMMON.LABEL.RESOLUTION_NOT_SUPPORTED' | translate }}"
    ></p-message>
  </div>
</div>

<p-confirmDialog
  header="Copy Claims Confirmation"
  icon="pi pi-exclamation-triangle"
  key="copy-claims"
  rejectButtonStyleClass="p-button-danger"
></p-confirmDialog>
